import $ from 'jquery';
import 'lazysizes';

window.jQuery = window.$ = $;

const theme = $('link[rel="stylesheet"][href*="main.css"]').length ? 'default' : 'mwph';

class Main {
    constructor() {
        this.initLazyloading();
        this.initAccordion();
        this.initCheckIn();
        this.initCollapse();
        this.initExpand();
        this.initEmbedContent();
        this.initDoctorRating();
        this.initAwesomplete();
        this.initFAD();
        this.initFilter();
        this.initFeatureGrid();
        this.initForm();
        this.initFullBgCta();
        this.initHpHero();
        this.initJumpLinks();
        this.initLiveFilter();
        this.initNavBreadcrumbs();
        this.initNavEntity();
        this.initNavPagination();
        this.initNavPrimary();
        this.initNavSearch();
        this.initNewPatient();
        this.initRtf();
        this.initSelectSearch();
        this.initSocialLinks();
        this.initStarRating();
        this.initTabs();
        this.initTabLinks();
        this.initTypeahead();
        this.initTypeaheadKryuus();
        this.initTypeaheadSSS();
        this.initTypeaheadSolr();
        this.initEpicModal();
        this.initSectionLanding();
        this.initScroll();
        this.initVideo();
        this.initVideoPlaylist();
    }

    initAccordion() {
        const $accordion = $('.js-accordion');

        if ($accordion.length < 1) {
            return;
        }

        import(/* webpackChunkName: "accordion" */ 'accordion/accordion').then(({ default: Accordion }) => {
            $accordion.each((key, wrap) => {
                new Accordion({
                    wrap: wrap
                });
            });
        });
    }

    initAdam() {
        const $adam = $('.mainContent');

        if ($adam.length < 1) {
            return;
        }

        import(/* webpackChunkName: "adam" */ 'adam/adam').then(({ default: Adam }) => {
            $adam.each((key, item) => {
                new Adam({
                    wrap: item
                });
            });
        });
    }

    initCheckIn() {
        const $checkIn = $('.js-check-in');

        if ($checkIn.length < 1) {
            return;
        }

        import(/* webpackChunkName: "check-in" */ 'check-in/check-in').then(({ default: CheckIn }) => {
            $checkIn.each((key, wrap) => {
                new CheckIn({
                    wrap: wrap
                });
            });
        });
    }

    initCollapse() {
        const $collapse = $('.js-collapse');

        if ($collapse.length < 1) {
            return;
        }

        import(/* webpackChunkName: "collapse" */ 'collapse/collapse').then(({ default: Collapse }) => {
            $collapse.each((key, item) => {
                new Collapse({ toggleButton: item });
            });
        });
    }

    initExpand() {
        const $expand = $('.js-expand');

        if ($expand.length < 1) {
            return;
        }

        import(/* webpackChunkName: "expand" */ 'expand').then(({ default: Expand }) => {
            $expand.each((key, wrap) => {
                new Expand({
                    wrap: wrap
                });
            });
        });
    }

    initDoctorRating() {
        const $doctorRating = $('.js-doctor-rating');

        if ($doctorRating.length < 1) {
            return;
        }

        import(/* webpackChunkName: "doctor-rating" */ 'doctor-rating/doctor-rating').then(({ default: DoctorRating }) => {
            $doctorRating.each((key, wrap) => {
                DoctorRating.init({
                    wrap: wrap
                });
            });
        });
    }

    initAwesomplete() {
        const $awesomPlete = $('.js-awesomplete');

        if ($awesomPlete.length < 1) {
            return;
        }

        import(/* webpackChunkName: "awesomplete" */ 'macro/awesomplete/awesomplete').then(({ default: AwesompleteAutocomplete }) => {
            $awesomPlete.each((key, wrap) => {
                new AwesompleteAutocomplete({
                    wrap: wrap
                });
            });
        });
    }

    initEmbedContent() {
        const $embed = $('.js-embed-content');

        if ($embed.length < 1) {
            return;
        }

        import(/* webpackChunkName: "embed-content" */ 'embed-content/embed-content').then(({ default: EmbedContent }) => {
            $embed.each((key, wrap) => {
                new EmbedContent({
                    wrap: wrap
                });
            });
        });
    }

    initFilter() {
        const $filter = $('.js-filter');

        if ($filter.length < 1) {
            return;
        }

        import(/* webpackChunkName: "filter" */ 'filter/filter').then(({ default: Filter }) => {
            $filter.each((key, wrap) => {
                new Filter({
                    wrap: wrap
                });
            });
        });
    }

    initFeatureGrid() {
        const $feature_grid = $('.js-feature-grid');

        if ($feature_grid.length < 1) {
            return;
        }

        import(/* webpackChunkName: "feature-grid" */ 'feature-grid/feature-grid').then(({ default: FeatureGrid }) => {
            $feature_grid.each((key, wrap) => {
                new FeatureGrid({
                    wrap: wrap
                });
            });
        });
    }

    initForm() {
        const $form = $('.js-form');

        if ($form.length < 1) {
            return;
        }

        import(/* webpackChunkName: "form" */ 'form/form').then(({ default: Form }) => {
            $form.each((key, wrap) => {
                new Form({
                    wrap: wrap
                });
            });
        });
    }

    initFullBgCta() {
        const $full_bg_ctas = $('.js-full-bg-cta');

        if ($full_bg_ctas.length < 1) {
            return;
        }

        import(/* webpackChunkName: "full-bg-cta" */ 'full-bg-cta/full-bg-cta').then(({ default: FullBgCta }) => {
            $full_bg_ctas.each((key, wrap) => {
                new FullBgCta({
                    wrap: wrap
                });
            });
        });
    }

    initJumpLinks() {
        const $links = $('.js-jump-links');

        if ($links.length < 1) {
            return;
        }

        import(/* webpackChunkName: "jump-links" */ 'jump-links/jump-links').then(({ default: JumpLinks }) => {
            $links.each((key, wrap) => {
                new JumpLinks({
                    wrap: wrap
                });
            });
        });
    }

    initHpHero() {
        const $hp_hero = $('.js-hp-hero');

        if ($hp_hero.length < 1) {
            return;
        }

        import(/* webpackChunkName: "hp-hero" */ 'hp-hero/hp-hero').then(({ default: HPHero }) => {
            $hp_hero.each((key, wrap) => {
                new HPHero({
                    wrap: wrap
                });
            });
        });
    }

    initLazyloading() {
        document.addEventListener('lazyloaded', function (e) {
            if (e.target.nodeName != 'IMG') {
                return;
            }
            if (e.target.parentElement.nodeName == 'PICTURE') {
                e.target.parentElement.classList.add('lazyloaded');
            } else {
                e.target.parentElement.classList.add('lazyloaded');
            }
        });

        lazySizes.init();
    }

    initLiveFilter() {
        const $live_filter = $('.js-live-filter');

        if ($live_filter.length < 1) {
            return;
        }

        import(/* webpackChunkName: "live-filter" */ 'live-filter/live-filter').then(({ default: LiveFilter }) => {
            $live_filter.each((key, wrap) => {
                LiveFilter.init({
                    wrap: wrap
                });
            });
        });
    }

    initNavBreadcrumbs() {
        const $nav_breadcrumbs = $('.js-nav-breadcrumbs');

        if ($nav_breadcrumbs.length < 1) {
            return;
        }

        import(/* webpackChunkName: "nav-breadcrumbs" */ 'nav-breadcrumbs/nav-breadcrumbs').then(({ default: NavBreadcrumbs }) => {
            $nav_breadcrumbs.each((key, wrap) => {
                NavBreadcrumbs.init({
                    wrap: wrap
                });
            });
        });
    }

    initNavEntity() {
        const $nav_entity = $('.js-nav-entity');

        if ($nav_entity.length < 1) {
            return;
        }

        import(/* webpackChunkName: "nav-entity" */ 'nav-entity/nav-entity').then(({ default: NavEntity }) => {
            $nav_entity.each((key, wrap) => {
                NavEntity.init({
                    wrap: wrap
                });
            });
        });
    }

    initNavPagination() {
        const $nav_pagination = $('.js-nav-pagination');

        if ($nav_pagination.length < 1) {
            return;
        }

        import(/* webpackChunkName: "nav-pagination" */ 'nav-pagination/nav-pagination').then(({ default: NavPagination }) => {
            $nav_pagination.each((key, wrap) => {
                NavPagination.init({
                    wrap: wrap
                });
            });
        });
    }

    initNavPrimary() {
        const $nav_primary = $('.js-nav-primary');

        if ($nav_primary.length < 1) {
            return;
        }

        import(/* webpackChunkName: "nav-primary" */ 'nav-primary/nav-primary').then(({ default: NavPrimary }) => {
            $nav_primary.each((key, wrap) => {
                NavPrimary.init({
                    wrap: wrap
                });
            });
        });
    }

    initNavSearch() {
        const $nav_search = $('.js-nav-search');

        if ($nav_search.length < 1) {
            return;
        }

        import(/* webpackChunkName: "nav-search" */ 'nav-search/nav-search').then(({ default: NavSearch }) => {
            $nav_search.each((key, wrap) => {
                NavSearch.init({
                    wrap: wrap
                });
            });
        });
    }

    initNewPatient() {
        const $nav_search = $('.js-nav-search');

        if ($nav_search.length < 1) {
            return;
        }

        import(/* webpackChunkName: "nav-search" */ 'nav-search/nav-search').then(({ default: NewPatient }) => {
            $nav_search.each((key, wrap) => {
                NewPatient.init({
                    wrap: wrap
                });
            });
        });
    }

    initRtf() {
        const $rtf = $('.rtf');

        if ($rtf.length < 1) {
            return;
        }

        import(/* webpackChunkName: "rtf" */ 'rtf/rtf').then(({ default: Rtf }) => {
            $rtf.each((key, wrap) => {
                new Rtf({
                    wrap: wrap
                });
            });
        });
    }

    initSelectSearch() {
        const $select_search = $('.js-select-search, .js-select-search-single');

        if ($select_search.length < 1) {
            return;
        }

        import(/* webpackChunkName: "select-search" */ 'select-search/select-search').then(({ default: SelectSearch }) => {
            $select_search.each((key, wrap) => {
                new SelectSearch({
                    wrap: wrap
                });
            });
        });
    }

    initSocialLinks() {
        const $social_links = $('.js-social-links');

        if ($social_links.length < 1) {
            return;
        }

        import(/* webpackChunkName: "social-links" */ 'social-links/social-links').then(({ default: SocialLinks }) => {
            $social_links.each((key, wrap) => {
                new SocialLinks({
                    wrap: wrap
                });
            });
        });
    }

    initStarRating() {
        const $star_rating = $('.js-star-rating');

        if ($star_rating.length < 1) {
            return;
        }

        import(/* webpackChunkName: "star-rating" */ 'star-rating/star-rating').then(({ default: StarRating }) => {
            $star_rating.each((key, wrap) => {
                new StarRating({
                    wrap: wrap
                });
            });
        });
    }

    initTabs() {
        const $tabs = $('.js-tabs');

        if ($tabs.length < 1) {
            return;
        }

        import(/* webpackChunkName: "tabs" */ 'tabs/tabs').then(({ default: Tabs }) => {
            $tabs.each((key, wrap) => {
                new Tabs({
                    wrap: wrap
                });
            });
        });
    }

    initTabLinks() {
        const $tab_links = $('.js-tab-links');

        if ($tab_links.length < 1) {
            return;
        }

        import(/* webpackChunkName: "tab-links" */ 'tab-links/tab-links').then(({ default: TabLinks }) => {
            $tab_links.each((key, wrap) => {
                new TabLinks({
                    wrap: wrap
                });
            });
        });
    }

    initTypeahead() {
        const $typeahead = $('.js-typeahead');

        if ($typeahead.length < 1) {
            return;
        }

        import(/* webpackChunkName: "typeahead" */ 'typeahead/typeahead').then(({ default: Typeahead }) => {
            $typeahead.each((key, wrap) => {
                new Typeahead({
                    wrap: wrap
                });
            });
        });
    }

    initTypeaheadKryuus() {
        const $typeahead = $('.js-typeahead-kryuus');

        if ($typeahead.length < 1) {
            return;
        }

        import(/* webpackChunkName: "typeahead-kryuus" */ 'typeahead/typeahead-kryuus').then(({ default: TypeaheadKryuus }) => {
            $typeahead.each((key, wrap) => {
                new TypeaheadKryuus({
                    wrap: wrap
                });
            });
        });
    }

    initTypeaheadSSS() {
        const $typeahead = $('.js-typeahead-sss');

        if ($typeahead.length < 1) {
            return;
        }

        import(/* webpackChunkName: "typeahead-sss" */ 'typeahead/typeahead-sss').then(({ default: TypeaheadSSS }) => {
            $typeahead.each((key, wrap) => {
                new TypeaheadSSS({
                    wrap: wrap
                });
            });
        });
    }

    initTypeaheadSolr() {
        const $typeahead = $('.js-typeahead-solr');

        if ($typeahead.length < 1) {
            return;
        }

        import(/* webpackChunkName: "typeahead-solr" */ 'typeahead/typeahead-solr').then(({ default: TypeaheadSolr }) => {
            $typeahead.each((key, wrap) => {
                new TypeaheadSolr({
                    wrap: wrap
                });
            });
        });
    }

    initEpicModal() {
        const $epic_modal = $('.js-epic-modal');

        if ($epic_modal.length < 1) {
            return;
        }

        import(/* webpackChunkName: "epic-modal" */ 'epic-modal/epic-modal').then(({ default: EpicModal }) => {
            $epic_modal.each((key, wrap) => {
                new EpicModal({
                    wrap: wrap
                });
            });
        });
    }

    initSectionLanding() {
        const $section_landing = $('.js-content-blocks');

        if ($section_landing.length < 1) {
            return;
        }

        import(/* webpackChunkName: "section-landing" */ 'section-landing/section-landing').then(({ default: SectionLanding }) => {
            $section_landing.each((key, wrap) => {
                new SectionLanding({
                    wrap: wrap
                });
            });
        });
    }

    initScroll() {
        if ($('.js-scroll').length && $('.js-scroll').data('scroll') === true) {
            $('html, body').animate({ scrollTop: $('.js-scroll').offset().top - 20 }, 500);
        }
    }

    initVideo() {
        const $media_video = $('.media-video');

        if ($media_video.length < 1) {
            return;
        }

        import(/* webpackChunkName: "media-video" */ 'media-video/media-video').then(({ default: MediaVideo }) => {
            $media_video.each((key, wrap) => {
                new MediaVideo({
                    wrap: wrap
                });
            });
        });
    }

    initVideoPlaylist() {
        const $video_playlist = $('.media-video-playlist');

        if ($video_playlist.length < 1) {
            return;
        }

        import(/* webpackChunkName: "video-playlist" */ 'video-playlist/video-playlist').then(({ default: VideoPlaylist }) => {
            $video_playlist.each((key, wrap) => {
                new VideoPlaylist({
                    wrap: wrap
                });
            });
        });
    }

    initFAD() {
        const $fad = $('.js-fad');

        if ($fad.length < 1) {
            return;
        }

        import(/* webpackChunkName: "fad" */ 'fad/fad').then(({ default: FAD }) => {
            $fad.each((index, el) => {
                new FAD(el);
            });
        });
    }
}


$(function () {
    new Main();
});


// UMMREDESGN-1062
if ($('.scfForm form')) {
    $('.scfForm form').submit(function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
}

// * Mini-scripts
$('.js-content-blocks').each(function (i, wrap) {
    $(wrap)
        .children()
        .each(function (i, block) {
            $(block).wrap('<div class="l-content__block"></div>');
        });
});

window.addEventListener('load', () => {
    document.querySelector('body').classList.remove('preload');

    //Focus on load on FAD input search

    if ($('#search-bar__input__keyword').length && !$('.site-search__results').length) {
        setTimeout(() => {
            $('#search-bar__input__keyword').trigger("focus");
        }, 1000);
    }
    if ($('#search-bar__input__keyword').length) {
        $('#search-bar__input__keyword').addClass("loaded");
    }
});

// * UMMFAD5-261 Add html tag parsing to Media Video's caption
$('.media-video__caption').each(function (i, wrap) {
    const videoTitle = $(wrap);
    const htmlString = videoTitle.text();
    const parsedText = new DOMParser().parseFromString(htmlString, 'text/html');

    videoTitle.text(parsedText.body.firstChild.textContent);
});
